<template>
  <div class="search_filter_wrap judge_modal">
    <div class="search_item_wrap judge_modal">
      <div class="search_input judge_modal">
        <el-input :placeholder="$t('st')" v-model="searchInput"
                  @keypress.enter.native="setFilterFunc('ni',searchInput)"/>
        <button class="search_btn" @click="setFilterFunc('ni',searchInput)"><span>{{$t('search')}}</span>
          <img src="@/assets/image/main/search_btn.png"></button>
      </div>
      <div class="end_filter">
        <!--        <button class="filter_btn two" @click="button>-->
        <el-popover
            popper-class="top_500"
            placement=""
            width="435"
            trigger="click"
            :open-delay="300"
            :offset="0"
            :append-to-body="false"
            :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
          <el-button slot="reference" class="filter_btn two" @click=""></el-button>
          <div class="popup_tail"></div>
          <div class="popup_bg">
            <div class="popup_inner">
              <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
              <div class="inner_middle">
                <div class="sub_title">{{$t('type')}}</div>
                <div class="type_wrap">
                  <el-checkbox-group v-model="filterType" @change="setFilterFunc('pr', filterType)">
                    <!--                                        <el-checkbox  >
                                                                {{$t('curator')}}
                                                            </el-checkbox>-->
                    <el-checkbox :label="1">
                      {{$t('special')}}
                    </el-checkbox>
                    <el-checkbox :label="0">
                      {{$t('normal')}}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="sub_title">{{$t('curator')}}</div>
                <el-checkbox-group v-model="filterCurator" @change="setFilterFunc('gid', filterCurator)">
                  <el-checkbox :label="1" >
                    {{$t('curator')}}
                  </el-checkbox>
                  <el-checkbox :label="2" >
                    {{$t('normal')}}
                  </el-checkbox>
                </el-checkbox-group>
                <div class="sub_title">{{$t('item')}}</div>
                <div class="price_wrap">
                  <el-input v-model="filterItem[0]" @keypress.native.enter="runRangeFilter()"
                            class="el_price_input el_input_num no_text" type="number"/>
                  <span class="first_input_step"> ~ </span>
                  <el-input v-model="filterItem[1]" @keypress.native.enter="runRangeFilter()"
                            class="el_price_input el_input_num" type="number"/>
                  <span> </span>
                </div>
                <div class="sub_title">{{$t('like')}}</div>
                <div class="price_wrap">
                  <el-input v-model="filterLike[0]" @keypress.native.enter="runRangeFilter()"
                            class="el_price_input el_input_num no_text" type="number"/>
                  <span class="first_input_step"> ~ </span>
                  <el-input v-model="filterLike[1]" @keypress.native.enter="runRangeFilter()"
                            class="el_price_input el_input_num" type="number"/>
                  <span> </span>
                </div>
              </div>
            </div>
          </div>
          <div class="wrapper_bottom" @click="runRangeFilter()">
            <button class="">{{$t('apply2')}}</button>
          </div>
        </el-popover>
      </div>
      <div class="filter_sort_box">
        <el-select id="sort_select" v-model="sortOption" class="sort_filter select "
                   popper-class="category_select judge_modal"
                   :popper-append-to-body="false"
                   @change="sortChange(sortOption)">
          <el-option :value="'mb_item_update'" :label="$t('b_update')"></el-option>
          <el-option :value="'itemCnt'" :label="$t('b_item')"></el-option>
          <el-option :value="'likeCnt'" :label="$t('b_like')"></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import memberUtil from "@/components/member/memberUtil";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import Filter from "@/mixins/filter/filter"

const {filterConfig} = memberUtil

export default {
  name: "MemberFilterJudgeLayout",
  mixins: [],
  components: {
  },
  inject: ['setFilter', 'setSort', 'setMemberListData'],
  provide() {
    return{

    }
  },
  props: {
    filterData: {default: () => {}},
    sortData: {default: () => {}},
    filterSet: {default: {filter: {}, page: {page: 1, size: 1, total: 0}, sort: {}}}
  },
  data() {
    return{
      searchOption: 'ina',
      sortOption: 'mb_item_update',
      searchInput: '',
      filterType: [],
      filterCurator: [],
      filterLike: ["", ""],
      filterItem: ["", ""],
      filterClass: new Filter(),
      showFilter: false,
    }
  },
  beforeRouterEnter() {},
  created() {
    this.filterClass.setterFilterConfigs(filterConfig);
    this.setterFilterDataInData();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    setterFilterDataInData() {
      let classnames = Object.keys(this.filterData);
      classnames.forEach(classname => {
        let obj = this.filterData[classname];
        let type = filterConfig[obj.key].type;
        if (this.hasOwnProperty(classname)) {
          // this[classname] =
          if (type == 'query') {
            this[classname] = obj.query;
            if (this.hasOwnProperty(classname + 'Option')) {
              this[classname + 'Option'] = obj.key;
            }
          } else if (type === 'between') {
            this[classname] = [obj.start, obj.end];
          } else if (type === 'match') {
            if (isNaN(obj.value)) {
              this[classname] = obj.value;
            } else {
              this[classname] = Number(obj.value);
            }

          } else if (type === 'terms') {
            this[classname] = obj.terms;
          }

        }
      })
      this.filterClass.filter = Object.assign({}, this.filterSet.filter);
      if(!util.isEmpty(this.sortData.key)) {
        this.sortOption = this.sortData.key;
      }
    },
    sortChange(data) {
      /*if (data === 'priceAsc') {
          this.setFilterFunc('obt', 'i_price');
          this.setFilterFunc('obs', 'asc');
      } else if (data === 'priceDesc') {
          this.setFilterFunc('obt', 'i_price');
          this.setFilterFunc('obs', 'desc');
      } else {
          this.setFilterFunc('obt', data);
          this.setFilterFunc('obs', 'desc');
      }*/
      this.setSort(data, 'desc')
    },
    setFilterFunc(key, value) {
      this.setFilter(key, value);
      this.filterClass.filter = Object.assign({}, this.filterSet.filter);
    },
    runRangeFilter() {
      let filterSet = this.filterSet;
      filterSet.filter = this.filterClass.setterFilter('lic', this.filterLike);
      filterSet.filter = this.filterClass.setterFilter('itc', this.filterItem);
      this.setMemberListData('filterSet', filterSet);
      this.setMemberListData('filterClass', this.filterClass);
      EventBus.$emit('memberListChangeSaveFilter')
      // this.showFilter = false;
      // this.setFilterFunc('lic', this.filterLike)

    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
